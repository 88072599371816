import { FlexContainer } from "@/uikit/container";
import { Block } from "../base";
import { BlockContainer } from "../base/block-container";
import { Typography } from "@/uikit/Typography";
import { buttonStyle, buttonText, contentWrapper, planPriceWrapper, priceCountStyle, priceWrapper, tariffPlan, tariffPlanListStyle, timeframeStyle, titleStyles, titleWraper } from "./pricing.css";
import IconCheck from '../../svg/icons/iconCheck.svg';
import Link from "next/link";

const Pricing = Block("pricing", ({ title, subtitle, price }) => {
    const handleLinkClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, href: string = '') => {
        if (href.startsWith("#")) {
            event.preventDefault();
            const targetId = href.substring(1);
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                const yOffset = -80;
                const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            } else {
                window.location.href = href;
            }
        }
    };

    return (
        <BlockContainer layout="centeredColumn" id="pricing" blockPaddingTop="medium" blockPaddingBottom="large">
            <FlexContainer alignItems="center" justifyContent="between" className={contentWrapper} gap="32">
                <FlexContainer direction="column" alignItems="start" className={titleWraper}>
                    {title && (
                        <Typography settings={{ tag: 'h3', mode: 'black' }} className={titleStyles}>
                            {title}
                        </Typography>
                    )}
                    {subtitle && (
                        <Typography settings={{ tag: 'description', mode: 'darkGrey' }} >
                            {subtitle}
                        </Typography>
                    )}
                </FlexContainer>
                {price && (
                    <FlexContainer direction="column" alignItems="start" justifyContent="start" className={priceWrapper}>
                        <div className={planPriceWrapper}>
                            <span className={priceCountStyle}>${price.priceCount}</span>
                            <span className={timeframeStyle}>{price.timeframe}</span>
                        </div>
                        <ul className={tariffPlan}>
                            {price.tariffPlan && price.tariffPlan.features && (
                                price.tariffPlan.features.map((feature: any, index: any) => (
                                    <li key={index} className={tariffPlanListStyle}>
                                        <IconCheck />
                                        <Typography settings={{ tag: 'tariffPlanList', mode: 'black' }}>{feature}</Typography>
                                    </li>
                                ))
                            )}

                        </ul>
                        <Typography settings={{ tag: 'priceText', mode: 'darkGrey' }}>{price.text}</Typography>
                        <button className={buttonStyle} onClick={(event) => handleLinkClick(event, price.contactButton?.link?.href)}>
                            <Typography settings={{ mode: "light", tag: "button" }} className={buttonText}>
                                {price.contactButton?.text}
                            </Typography>
                        </button>
                    </FlexContainer>
                )}
            </FlexContainer>
        </BlockContainer>
    )
})

export default Pricing;