import { PageSchema } from '@/schema';
import React from 'react';

type PageBlocks = NonNullable<PageSchema['blocks']>[0];

export type BlockTypes = PageBlocks['_type'];

export type BlockType<T extends BlockTypes> = Extract<PageBlocks, { _type: T }>;

interface BasicBlockProps {
    className?: string;
}

export function Block<T extends BlockTypes>(
    type: T,
    component: React.FC<BlockType<T> & BasicBlockProps>,
) {
    const blockComponent = React.memo(component);

    blockComponent.displayName = `Block:${type}`;

    return blockComponent;
}

interface BlockConfig<C extends BlockType<BlockTypes>> {
    component: React.ComponentType<BasicBlockProps & C>;
}

export type BlockMapping = {
    [key in BlockTypes]: BlockConfig<BlockType<key>> | undefined;
};