import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FPricing%2Fpricing.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61U24rbMBR8368QLIVdqIJ8T5SXblr2N4piy7ZaWxKSvE5a8u%2FFip1YjnNhKYQEn4znzJlzWfz0apmEpEXg7xMAGdOyInsM8oru1k8ASJJljBcYxEjaQEEkBoF%2FfNgKlVEFFclYozEIe8yvRhuW72EquKHcYKAlSSncUtNSyu2LJP1dKNHwDIPnzeZ9%2BeOtC9dkB1uWmRIDLwi9ju3wtBgk%2BlZi%2F3%2B4iqWr8IoovxflpMzz%2FAjdQV2STLQYILkDXvfVVWGfVLElL%2Bgr6D8LD706egKrp2LaQG32FYVmLykGXHC6nvOy%2B4UZUzQ1THAMUlE1tbWDVKzgkBla6yMcakOUOfnt9bUecZpWOQbaKGrS0lEUzjfRoU8pN1SdqJcTk6N5isH1weRpLUq0VwVOBGyJphXrPBqljW3aVFRCYfCMELJJBDdQsz%2F0PFo21FJWlAaD5AjryGDZx7wQfXGYkzvMfjjDHD7CvHSYwzCcMHufZl7dWUY7p%2F61bTt3%2BFbjvf71Yd9Qp%2BFie1ar8VKdh9vZJu8tSd79Lpw2SnduSMGGZDVRBePQCDl4PS4Ul%2BKDKluuQ4mS2O9ugoMlqWEfdAYcR5vvGwdMLGrcjPizA7Qdn504CiYlpHemwJ9LPD8FkU38raYZI%2BBlfAzDYCV3rzbTRJd7NPsmXnT1cIUX%2BcEFL7rkTVA%2FLLeO2GFM4vUkzsheoPzrJTi46EHcf7AkiZNHHDnTTham4Zqa9aT26EbtJ5R%2FcujwD9CPvFmWBwAA%22%7D"
export var buttonStyle = '_1mp74aw9';
export var buttonText = '_1mp74awa';
export var contentWrapper = '_1mp74aw0';
export var planPriceWrapper = '_1mp74aw5';
export var priceCountStyle = '_1mp74aw6';
export var priceTextStyle = '_1mp74aw8';
export var priceWrapper = '_1mp74aw2';
export var subtitleStyles = '_1mp74awc';
export var tariffPlan = '_1mp74aw3';
export var tariffPlanListStyle = '_1mp74aw4';
export var timeframeStyle = '_1mp74aw7';
export var titleStyles = '_1mp74awb';
export var titleWraper = '_1mp74aw1';